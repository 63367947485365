import { useEffect } from "react";
import queryString from "query-string";
import { push } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";
import { validateStravaToken } from "../redux/actions/auth";

const Token = () => {
  const location = useSelector((state) => state.router.location);
  // Get the current auth state and setup a dispatch action
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    // Check if the user/app is authenticated
    const { code } = queryString.parse(location.search);
    if (!auth.isAuthenticated && code) {
      // pull the code out of the query string
      const { code } = queryString.parse(location.search);
      // dispatch the validation code to be handled by saga
      dispatch(validateStravaToken(code));
    } else {
      // Push back to home page because the user is authenticated or there is no code!
      dispatch(push("/link-activity"));
    }
    // eslint-disable-next-line
  }, []);

  // Return nothing because this page had nothing to display
  return null;
};

export default Token;
