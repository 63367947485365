import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { loadUser } from "../localStorage";
import "../styles/App.css";

import { logoutUser } from "../redux/actions/kouoAuth";

const Main = () => {
  const [user, setUser] = useState({});
  const kouoAuth = useSelector((state) => state.kouoAuth);
  useEffect(() => {
    // Check if the user/app is authenticated
    if (!kouoAuth.isKouoAuthenticated) {
      dispatch(put(push("/")));
    } else {
      setUser(loadUser);
    }
    // eslint-disable-next-line
  }, []);

  const highlightOn = (event) => {
    event.stopPropagation();
    if (event.target.tagName !== ("DIV" || "div")) return;
    event.target.classList.add("highlight");
  };
  const highlightOff = (event) => {
    event.stopPropagation();
    if (event.target.tagName !== ("DIV" || "div")) return;
    event.target.classList.remove("highlight");
  };

  const dispatch = useDispatch();
  return (
    <div>
      <div className="row">
        <div style={{ flex: "1" }}></div>
        <div style={{ flex: "1" }}>
          <img
            style={{ height: "100px", margin: "auto" }}
            src={require("../assets/kouo.png").default}
            alt="Kouo"
          />
        </div>
        <div style={{ flex: "1" }}>
          <button
            onClick={() => {
              setUser();
              dispatch(logoutUser());
            }}
            className="box align-center"
            style={{ margin: "1rem", height: "40px" }}
          >
            Logout
          </button>
        </div>
      </div>

      <div className="container align-center">
        <p style={{ textAlign: "center" }}>
          Check out more about Kouo and what we are doing at{" "}
          <a href="kouo.io" style={{ color: "#777" }}>
            kouo.io
          </a>
          .
        </p>
      </div>
      <h1 style={{ margin: "2rem auto", padding: "1rem" }}>Dashboard</h1>
      {/* {auth.isAuthenticated ? (
        <h2>Logged in</h2>
      ) : (
        // add the dispatch to the button onClick
        <button
          type="button"
          onClick={() => dispatch(beginStravaAuthentication())}
        >
          Authorise on Strava
        </button>
      )} */}
      {/* <div
        className="list-item box"
        onClick={() => {
          if (auth.isAuthenticated) return;
          dispatch(beginStravaAuthentication());
        }}
        onMouseEnter={highlightOn}
        onMouseLeave={highlightOff}
        style={{
          backgroundColor: auth.isAuthenticated ? "whitesmoke" : "white",
        }}
      >
        <p style={{ fontSize: "1.2rem", textAlign: "center" }}>
          Connect Strava
        </p>
        {auth.isAuthenticated ? (
          <img
            className="complete-tick"
            src={require("../assets/tick.svg").default}
            alt="completed"
          />
        ) : null}
      </div> */}
      <div className="container align-center text-contained">
        <p style={{ textAlign: "center" }}>
          {" "}
          If this is your first time here, please take a moment to indicate your
          personal preferences.
        </p>
      </div>
      <div
        className="list-item box"
        onClick={(e) => {
          e.preventDefault();
          window.location.assign(
            `https://form.typeform.com/to/ywRjkvSy#userid=${user.id}&username=${user.username}`
          );
        }}
        onMouseEnter={highlightOn}
        onMouseLeave={highlightOff}
        style={{
          backgroundColor: "black",
        }}
      >
        <p
          style={{
            fontSize: "1.2rem",
            textAlign: "center",
            color: "white",
          }}
        >
          {"Personal Preferences"}
        </p>
      </div>
      <h2 style={{ margin: "0.5rem auto" }}>Quick Links</h2>
      <div
        className="list-item box"
        onClick={(e) => {
          e.preventDefault();
          window.location.assign(
            `https://kouo-mycelium-demo.anvil.app/`
          );
        }}
        onMouseEnter={highlightOn}
        onMouseLeave={highlightOff}
        style={{
          backgroundColor: "white",
        }}
      >
        <p style={{ fontSize: "1.2rem", textAlign: "center" }}>
          {"Dashboard"}
        </p>
      </div>
      <h2 style={{ margin: "0.5rem auto" }}>Happiness Skills</h2>
      <div
        className="list-item box"
        onClick={(e) => {
          dispatch(push("/creativity-timer"));
        }}
        onMouseEnter={highlightOn}
        onMouseLeave={highlightOff}
        style={{
          backgroundColor: "white",
        }}
      >
        <p style={{ fontSize: "1.2rem", textAlign: "center" }}>
          {"Creativity Timer"}
        </p>
      </div>
      <div
        className="list-item box"
        onClick={(e) => {
          dispatch(push("/power-rituals"));
        }}
        onMouseEnter={highlightOn}
        onMouseLeave={highlightOff}
        style={{
          backgroundColor: "white",
        }}
      >
        <p style={{ fontSize: "1.2rem", textAlign: "center" }}>Power Rituals</p>
      </div>

      <div className="container align-center text-contained">
        <p style={{ textAlign: "center" }}>
          {" "}
          If you have any feedback for the team, please click to complete our
          feedback form.
        </p>
      </div>
      <div
        className="list-item box"
        onClick={(e) => {
          e.preventDefault();
          window.location.assign(
            `https://form.typeform.com/to/D99Lg3Nk#userid=${user.id}&username=${user.username}`
          );
        }}
        onMouseEnter={highlightOn}
        onMouseLeave={highlightOff}
        style={{
          backgroundColor: "white",
        }}
      >
        <p style={{ fontSize: "1.2rem", textAlign: "center" }}>Feedback</p>
      </div>
      <div
        className="list-item box"
        onClick={(e) => {
          e.preventDefault();
          window.location.assign(`https://kouo.io/privacy`);
        }}
        onMouseEnter={highlightOn}
        onMouseLeave={highlightOff}
        style={{
          backgroundColor: "white",
        }}
      >
        <p style={{ fontSize: "1.2rem", textAlign: "center" }}>
          {"Privacy Policy"}
        </p>
      </div>
    </div>
  );
};

export default Main;
