import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { loadUser } from "../localStorage";
import "../styles/App.css";

const PowerRituals = () => {
  const [, setUser] = useState({});
  const kouoAuth = useSelector((state) => state.kouoAuth);
  useEffect(() => {
    // Check if the user/app is authenticated
    if (!kouoAuth.isKouoAuthenticated) {
      dispatch(push("/"));
    } else {
      setUser(loadUser);
    }
    // eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();
  return (
    <div>
      <div className="row">
        <div style={{ flex: "1" }}></div>
        <div style={{ flex: "1" }}>
          <img
            style={{ height: "100px", margin: "auto" }}
            src={require("../assets/kouo.png").default}
            alt="Kouo"
          />
        </div>
        <div style={{ flex: "1" }}>
          <button
            onClick={() => {
              dispatch(push("/link-activity"));
            }}
            className="box align-center"
            style={{ margin: "1rem", height: "40px" }}
          >
            Return to home
          </button>
        </div>
      </div>

      <div className="container blog-like align-center ">
        <p style={{ textAlign: "center" }}>
          Check out more about Kouo and what we are doing at{" "}
          <a href="kouo.io" style={{ color: "#777" }}>
            kouo.io
          </a>
          .
        </p>
        <h1 style={{ margin: "2rem auto" }}>Power Rituals</h1>
        <p className="blog-like">
          One step that you can take to regulate anxiety, be happier and be more
          effective in your life is with the use of a power ritual. For this,
          you can take a practice that is personally significant to you and
          engage with it on a regular basis. But you can’t just go through the
          motions – before you embark on your ritual, you need to mark it out
          and have intention of doing it.
        </p>
        <p className="blog-like">
          One good way to ensure this is with a physical routine. Once you’ve
          started, you need to pay attention – focus your mind on the ritual and
          be mindful that it is happening. Power rituals might seem like
          superstition at first glance, but they are a tool used by a wide range
          of high-achieving people – from the first man in space Yuri Gagarin to
          tennis great Serena Williams.
        </p>
        <p className="blog-like">
          However, we don’t want you to feel like your ritual needs to replicate
          theirs – it can be any small act that brings you comfort and spiritual
          solidity. So pick what works for you.
        </p>
        <p></p>
        <p></p>
      </div>
    </div>
  );
};

export default PowerRituals;
