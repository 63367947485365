import { takeLeading, call, put } from "redux-saga/effects";
import {
  KOUO_AUTH_RESET_PASS,
  KOUO_AUTH_FORGOT_PASS,
  KOUO_AUTH_UPDATE_USER,
} from "../constants/kouoAuth";
import { updateUser, logoutUser } from "../actions/kouoAuth";
import { push, replace } from "connected-react-router"; // add
import User from "../../api/User";

export function* logoutKouoAsync() {
  yield takeLeading(KOUO_AUTH_UPDATE_USER, logoutKouo);
}

function* logoutKouo() {
  yield put(logoutUser());
  yield put(replace("/"));
}

export function* forgotKouoPassAsync() {
  // This will listen for the first STRAVA_AUTH_START and then call beginStravaAuthentication
  yield takeLeading(KOUO_AUTH_FORGOT_PASS, forgotKouoPass);
}

function* forgotKouoPass({ payload: email }) {
  const response = yield call(submitResetRequest, email);
  if (response) {
    yield put(push("/"));
  }
}

async function submitResetRequest(email) {
  console.log("Reseting pass", email);
  let response = await User.forgotPass({
    email: email,
  });
  if (response.status === 200) {
    alert("Request submitted successfully. Please check your email to proceed");
    return true;
  } else {
    alert(
      "There was an error submitting the request. Please reload and try again"
    );
    return false;
  }
}

export function* resetKouoPassAsync(params) {
  yield takeLeading(KOUO_AUTH_RESET_PASS, resetKouoPass);
}

function* resetKouoPass({ payload: { code, password } }) {
  const response = yield call(submitNewPass, code, password);

  if (handleResetResponse(response)) {
    yield put(updateUser(response.data));
    yield put(push("/link-activity"));
  } else {
    alert("Try again");
  }
}

async function submitNewPass(token, pass) {
  let response = await User.resetPassword({
    code: token,
    password: pass,
    passwordConfirmation: pass,
  });
  return response;
}

function handleResetResponse(response) {
  if (response.status === 200) {
    alert("Password updated successfully");
    return true;
  } else {
    let message = response.data.message[0].messages[0].message;
    alert("Unable to reset password: " + message);
    return false;
  }
}
