import { takeLeading } from "redux-saga/effects";
import { KOUO_AUTH_SIGN_UP } from "../constants/kouoAuth";

export function* beginKouoRegisterAsync() {
  yield takeLeading(KOUO_AUTH_SIGN_UP, beginKouoRegistration);
}

function* beginKouoRegistration({
  payload: { email, password, language, gender, age },
}) {
  // A simple generator function
  // Just yeilds one other function - handOffTo
    alert(
      "Registration successfull"
    );
  yield window.location.assign(
    `https://kouo-mycelium.anvil.app/`
  );
}

