import { all } from "redux-saga/effects";

// Import the new async function we created above
import { beginStravaAuthAsync, validateStravaTokenAsync } from "./auth";
import { forgotKouoPassAsync, resetKouoPassAsync } from "./kouoAuth";
import { beginKouoLoginAsync } from "./kouoLogin";
import { beginKouoRegisterAsync } from "./kouoRegister";

export default function* rootSaga() {
  // Add beginStravaAuthAsync to the rootSaga
  yield all([
    beginStravaAuthAsync(),
    beginKouoRegisterAsync(),
    validateStravaTokenAsync(),
    beginKouoLoginAsync(),
    forgotKouoPassAsync(),
    resetKouoPassAsync(),
  ]);
}
