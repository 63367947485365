// file: src/configureStore.js
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import throttle from "lodash/throttle";
import { loadState, saveState } from "./localStorage"; // Import the load and save

import createRootReducer from "./redux/reducers";
import rootSaga from "./redux/sagas";

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

const persistedState = loadState();

const configureStore = () => {
  const enhancers = [];
  const __DEV__ = process.env.REACT_APP_ENV !== "production";
  if (__DEV__) {
    const { devToolsExtension } = window;
    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension());
    }
  }

  const middleware = [routerMiddleware(history), sagaMiddleware];
  const rootReducer = createRootReducer(history);

  // replace initialState with persistedState so on boot the app will use the data that exists in the state
  const store = createStore(
    rootReducer,
    persistedState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );

  sagaMiddleware.run(rootSaga);

  // subscribe to store updates so we can action saves on change to state
  store.subscribe(
    // throttle the save
    throttle(() => {
      saveState({
        auth: store.getState().auth, // Only save these sections of the auth
        kouoAuth: store.getState().kouoAuth, // Only save these sections of the auth
        // if we want to save other state we can add it in here
        // activities: store.getState().activities, etc!
      });
    }, 1000) // 1 per second max!
  );

  return store;
};

export default configureStore();
