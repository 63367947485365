import { takeLeading, call } from "redux-saga/effects";
import { KOUO_AUTH_LOG_IN } from "../constants/kouoAuth";

export function* beginKouoLoginAsync(params) {
  yield takeLeading(KOUO_AUTH_LOG_IN, beginKouoLogin);
}

function* beginKouoLogin({ payload: { username, password } }) {
  // A simple generator function
  // Just yeilds one other function - handOffTo
  const user = yield call(loginWithStrapi, username, password);
  if (user != null) {
    console.log("authenticated")
    window.location.assign(
      `https://kouo-mycelium.anvil.app/`
    );
  } else {
    yield window.location.reload();
  } 
}

async function loginWithStrapi(username, password) {
  if (username === "shaan@kouo.io" && password === "the_elephant") {
    
    console.log("In here")
    return "Not bad"
  } else {
    alert("Wrong username/password combination")
  }
}

