import React from "react";
import "../styles/App.css";
import Mailchimp from "react-mailchimp-form";

const SignupForm = () => {
  return (
    <div>
      <div className="row">
        <div style={{ flex: "1" }}></div>
        <div style={{ flex: "1" }}>
          <img
            style={{ height: "100px", margin: "auto" }}
            src={require("../assets/kouo.png").default}
            alt="Kouo"
          />
        </div>
        <div style={{ flex: "1" }}></div>
      </div>

      <div className="form-container align-center ">
        <p style={{ textAlign: "center" }}>
          Check out more about Kouo and what we are doing at{" "}
          <a href="kouo.io" style={{ color: "#777" }}>
            kouo.io
          </a>
          .
        </p>
        <h1 style={{ margin: "2rem auto" }}>Pilot Signup </h1>
        <p>Thanks for expressing an interest in Kouo's pilot study.</p>
        <p>
          Mental health has a huge impact on everything we do, from work to
          relationships. However, we still lack the right tools to look after it
          effectively and preventively. That's why at Kouo, we've started
          developing mental wellbeing solutions that are easy to use and
          seamlessly adapt to our everyday needs.
        </p>
        <p>
          Our mission since inception was to help redefine how we connect and
          interact with the world by addressing one of the most important
          factors in our lives, our mental health.
        </p>
        <p>
          To learn more about how the pilot study works and to start using
          better mental health tools just fill in your details below.
        </p>
      </div>
      <div className="mc__form-container">
        <Mailchimp
          action="https://kouo.us20.list-manage.com/subscribe/post?u=11a28844af1a07744e5c73e4f&amp;id=43e936bac0"
          //Adding multiple fields:
          fields={[
            {
              name: "FNAME",
              placeholder: "Name",
              type: "text",
              required: true,
            },
            {
              name: "LNAME",
              placeholder: "Surname",
              type: "text",
              required: true,
            },
            {
              name: "EMAIL",
              placeholder: "Email",
              type: "email",
              required: true,
            },
          ]}
          // Change predetermined language
          messages={{
            sending: "Sending...",
            success: "Thank you for subscribing!",
            error: "An unexpected internal error has occurred.",
            empty: "You must write an e-mail.",
            duplicate: "Too many subscribe attempts for this email address",
            button: "Sign Up",
          }}
          // Add a personalized class
          className="mc__form"
        />
      </div>
    </div>
  );
};

export default SignupForm;
