import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { loadUser } from "../localStorage";
import "../styles/App.css";

const CreativityTimer = () => {
  const [, setUser] = useState({});
  const kouoAuth = useSelector((state) => state.kouoAuth);
  useEffect(() => {
    // Check if the user/app is authenticated
    if (!kouoAuth.isKouoAuthenticated) {
      dispatch(push("/"));
    } else {
      setUser(loadUser);
    }
    // eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();
  return (
    <div>
      <div className="row">
        <div style={{ flex: "1" }}></div>
        <div style={{ flex: "1" }}>
          <img
            style={{ height: "100px", margin: "auto" }}
            src={require("../assets/kouo.png").default}
            alt="Kouo"
          />
        </div>
        <div style={{ flex: "1" }}>
          <button
            onClick={() => {
              dispatch(push("/link-activity"));
            }}
            className="box align-center"
            style={{ margin: "1rem", height: "40px" }}
          >
            Return to home
          </button>
        </div>
      </div>

      <div className="container blog-like align-center ">
        <p style={{ textAlign: "center" }}>
          Check out more about Kouo and what we are doing at{" "}
          <a href="kouo.io" style={{ color: "#777" }}>
            kouo.io
          </a>
          .
        </p>
        <h1 style={{ margin: "2rem auto" }}>Creativity Timer</h1>
        <p className="blog-like">
          Imagine you’ve been given a task or a problem to solve. It can be a
          hard problem with no obvious answers (ideation or brainstorming
          sessions) or maybe one with more tangible outcomes. It requires your
          creativity and you set out to work on it pretty much until you feel
          stuck or you need to switch over to another task.
        </p>
        <p className="blog-like">
          It’s a pretty normal reaction, we’d do the same and in fact, most
          people asked would do the same. This approach seemingly gives us the
          chance to have maximum autonomy when it comes to getting on with our
          days and the tasks at hand.
        </p>
        <p className="blog-like">
          However, studies show us this isn’t the most productive or meaningful
          way to approach a task that requires creative thinking. Mainly because
          when we reach a ‘creativity dead end’, we don’t realise it at all
          using this method. After all, it’s extremely difficult to recognise
          when rigid thinking starts creeping in.
        </p>
        <p className="blog-like">
          So instead of leaving it up to yourself to switch between tasks, we’d
          recommend you set regular intervals using a timer. When the timer
          goes, you switch to another task. This might feel counterintuitive to
          some, especially when you’re feeling like you’re on a roll and you’ve
          got loads of ideas, but you should probably still do it.
        </p>
        <p className="blog-like">
          In a study on Organizational Behaviour and Human Decision Process,
          people who didn’t step away from the tasks set, at regular intervals,
          were more likely to come up with ‘new’ ideas that were, in fact, all
          very similar to one another. Subjectively they could’ve felt like they
          were conquering the task, but looking at their outputs objectively
          there weren’t qualitatively different.
        </p>
        <p className="blog-like">
          The reason for all this? Our thinking needs a reset at regular
          intervals to allow us to be able to come up with ideas that are
          actually fresh and interesting. Just like painters step away from
          their work, we need to step back at regular intervals so when we go
          back to the problem we are looking at it with fresh eyes.
        </p>
        <p className="blog-like">
          So, no matter where you are; working from home with no natural
          distractions, or at the office with lots of distractions, make sure
          you set a timer at regular intervals to break up your day better and
          to get your best ideas out there
        </p>
        <p className="blog-like">
          To read more about scheduled breaks, task switching or to read the
          study quoted, follow these links: <br></br>
          <a href="https://hbr.org/2017/05/to-be-more-creative-schedule-your-breaks">
            Creative Schedule Break
          </a>
          <br></br>
          <a href="https://www.sciencedirect.com/science/article/abs/pii/S074959781630108X">
            “Switching On” creativity
          </a>
        </p>
        <p></p>
        <p></p>
      </div>
    </div>
  );
};

export default CreativityTimer;
