import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import storeConfig, { history } from "./configureStore";


// Update
ReactDOM.render(
    <React.StrictMode>
        <Provider store={storeConfig}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
        </Provider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
