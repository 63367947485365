import axios from "axios";
import conf from "./apiconfig.json";

let env = process.env.REACT_APP_ENV || "dev";

interface LoginBody {
  identifier: string;
  password: string;
}

interface RegisterBody {
  username: string;
  email: string;
  gender: string;
  age: number;
  language: string;
  password: string;
}

const User = {
  load: (): object | null => {
    let user = localStorage.getItem("user");
    if (user) return JSON.parse(user);
    return null;
  },
  remove: (): object | null => {
    localStorage.removeItem("user");
    return null;
  },
  me: async (jwt: string): Promise<any> => {
    return axios
      .get(conf["envs"][env]["strapi-url"] + `/users/me`, {
        headers: { Authorization: "Bearer " + jwt },
      })
      .then((response) => {
        let formattedUser = { ...response.data, jwt };
        localStorage.setItem("user", JSON.stringify(formattedUser));
        return formattedUser;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  },
  logout: (): object | null => {
    localStorage.removeItem("user");
    return User.load();
  },
  login: async (body: LoginBody): Promise<any> => {
    return axios
      .post(conf.envs[env]["strapi-url"] + "/auth/local", body)
      .then((response) => {
        let formattedUser = { ...response.data.user, jwt: response.data.jwt };
        return formattedUser;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
  },
  register: async (body: RegisterBody): Promise<any> => {
    return axios
      .post(conf.envs[env]["strapi-url"] + "/auth/local/register", body)
      .then((response: { data: { user: any; jwt: any } }) => {
        let formattedUser = { ...response.data.user, jwt: response.data.jwt };
        return formattedUser;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
  },
  forgotPass: async (body: RegisterBody): Promise<any> => {
    return axios
      .post(conf.envs[env]["strapi-url"] + "/auth/forgot-password", body)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
  },
  resetPassword: async (body: any): Promise<any> => {
    return axios
      .post(conf.envs[env]["strapi-url"] + "/auth/reset-password", body)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
  },
  validateEmail: async (code: string): Promise<any> => {
    const url = `/auth/email-confirmation?confirmation=${code}`;
    return axios
      .get(conf.envs[env]["strapi-url"] + url)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
  },
  update: async (userid: string, body: any, jwt: string): Promise<any> => {
    return axios
      .put(conf.envs[env]["strapi-url"] + `/users/${userid}`, body, {
        headers: { Authorization: "Bearer " + jwt },
      })
      .then((response) => {
        let formattedUser = { ...response.data, jwt: jwt };
        localStorage.setItem("user", JSON.stringify(formattedUser));
        return formattedUser;
      })
      .catch((error) => {
        return console.log(error);
      });
  },
  updateCompletedSessions: async (user: any, session: any): Promise<any> => {
    const compSessions = user.completedSessions ? user.completedSessions : [];
    return User.update(
      user.id,
      {
        ...user,
        completedSessions: [
          ...compSessions,
          {
            id: session.id,
            title: session.Title,
            timeCompleted: Date.now(),
          },
        ],
      },
      user.jwt
    );
  },
  updateExternalTokens: async (user: any): Promise<any> => {
    const external_tokens = user.external_tokens ? user.external_tokens : {};
    return User.update(
      user.id,
      {
        ...user,
        external_tokens: external_tokens,
      },
      user.jwt
    );
  },
  getNewAuthToken: async (jwt: string): Promise<any> => {
    return axios
      .get(conf.envs[env]["strapi-url"] + "/datasets/getAuthToken/", {
        headers: { Authorization: "Bearer " + jwt },
        params: {
          expires_after: conf.configVars.tokenExpiresAfter,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return console.log(error.response);
      });
  },
};

export default User;
