import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { resetPass } from "../redux/actions/kouoAuth";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";

import "../styles/App.css";
import "../styles/login.css";

const ResetPass = () => {
  const [newPass, setNewPass] = useState("");
  const [newPassConf, setNewPassConf] = useState("");
  const [code, setCode] = useState();
  const location = useSelector((state) => state.router.location);
  // Get the current auth state and setup a dispatch action
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    // Check if the user/app is authenticated
    const { code } = queryString.parse(location.search);
    if (!auth.isAuthenticated && code) {
      // pull the code out of the query string
      setCode(queryString.parse(location.search).code);
      // dispatch the validation code to be handled by saga
    } else {
      dispatch(push("/"));
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <div className="row">
        <div style={{ flex: "1" }}></div>
        <div style={{ flex: "1" }}>
          <img
            style={{ height: "100px", margin: "auto" }}
            src={require("../assets/kouo.png").default}
            alt="Kouo"
          />
        </div>
        <div style={{ flex: "1" }}></div>
      </div>

      <div className="container align-center">
        <p style={{ textAlign: "center" }}>
          Check out more about Kouo and what we are doing at{" "}
          <a href="kouo.io" style={{ color: "#777" }}>
            kouo.io
          </a>
          .
        </p>
        <p style={{ fontSize: "0.8rem" }}>
          Please fill in your new password and the confirmation and than press
          submit.
        </p>
      </div>
      <div className="row margin-center justify-center">
        <div className="login-column">
          <input
            id="password"
            type="password"
            value={newPass}
            placeholder="New Password"
            onChange={(e) => setNewPass(e.target.value)}
          ></input>
          &nbsp; &nbsp;
          <input
            id="password"
            type="password"
            value={newPassConf}
            placeholder="Confirm New Password"
            onChange={(e) => setNewPassConf(e.target.value)}
          ></input>
          &nbsp; &nbsp;
          <button
            onClick={() => {
              if (newPass !== newPassConf) {
                alert("Password does not match");
                setNewPass("");
                setNewPassConf("");
              } else {
                dispatch(resetPass(code, newPass));
              }
              setNewPass("");
              setNewPassConf("");
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPass;
