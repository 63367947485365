import { takeLeading, call, put } from "redux-saga/effects";
import {
  STRAVA_AUTH_START,
  STRAVA_AUTH_TOKEN_VALIDATE,
} from "../constants/auth";
import { updateAuthTokens } from "../actions/auth";
import { push } from "connected-react-router"; // add

import { tokenClient } from "../../api/index"; // add
import User from "../../api/User";

const clientID = process.env.REACT_APP_STRAVA_CLIENT_ID;
// Add! But remember this should only ever be done for non PROD.
// The secret should be on a non client app (ie backend or lambda like Netlify)
const clientSecret = process.env.REACT_APP_STRAVA_CLIENT_SECRET;

const apiValidateToken = (code) => {
  return tokenClient({
    url: "/token",
    method: "post",
    params: {
      client_id: clientID,
      client_secret: clientSecret,
      code,
      grant_type: "authorization_code",
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      Promise.reject(error);
    });
};

async function updateRemoteUserTokens(token_data) {
  let formatted_external_tokens = {
    strava: {
      refreshToken: token_data.refresh_token,
      expiresAt: token_data.expires_at,
      accessToken: token_data.access_token,
    },
  };
  let current_user = JSON.parse(localStorage.getItem("user"));
  current_user.external_tokens = formatted_external_tokens;

  await User.updateExternalTokens(current_user);
}

function* validateStravaToken({ payload: code }) {
  // here you could dispatch a loading start action!

  // we dispatch a api call to validate the tokens and it returns a data object with the values we need.
  const data = yield call(apiValidateToken, code);
  // push an the action type: STRAVA_AUTH_TOKEN_UPDATE with the data
  yield put(updateAuthTokens(data));

  yield call(updateRemoteUserTokens, data);

  yield put(push("/link-activity")); // Push the browser to the root when we are done!

  // here you could dispatch a loading end action!
}

export function* validateStravaTokenAsync() {
  // Listen for STRAVA_AUTH_TOKEN_VALIDATE
  yield takeLeading(STRAVA_AUTH_TOKEN_VALIDATE, validateStravaToken);
}

function handOffToStravaAuth() {
  // Get the current address of the app running eg localhost or mycoolapp.io
  const { origin } = window;
  // push the Strava authorise url onto the browser window with our PUBLIC clientID and the return url (origin)
  // Note the redirect_uri=${origin}/token - this is the token page we setup earlier.
  window.location.assign(
    `https://www.strava.com/oauth/authorize?client_id=${clientID}&redirect_uri=${origin}/token&response_type=code&scope=activity:read_all`
  );
}

function* beginStravaAuthentication() {
  // A simple generator function
  // Just yeilds one other function - handOffToSshpiragu01
  yield call(handOffToStravaAuth);
}

export function* beginStravaAuthAsync() {
  // This will listen for the first STRAVA_AUTH_START and then call beginStravaAuthentication
  yield takeLeading(STRAVA_AUTH_START, beginStravaAuthentication);
}
