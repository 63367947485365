/* eslint-disable import/no-anonymous-default-export */
import { STRAVA_AUTH_TOKEN_UPDATE } from "../constants/auth"; // Import the constant for the action
const initialState = {
  isAuthenticated: false,
};

export default function (state = initialState, action) {
  if (!action) return state;

  switch (action.type) {
    // The new bits
    case STRAVA_AUTH_TOKEN_UPDATE:
      // save the tokens to the local storage
      localStorage.setItem("refreshToken", action.payload.refreshToken);
      localStorage.setItem("expiresAt", action.payload.expiresAt);
      localStorage.setItem("accessToken", action.payload.accessToken);

      // update the state to show the app the user is logged in
      return { ...state, isAuthenticated: true };

    default:
      return state;
  }
}
