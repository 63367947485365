import React, { useState, useEffect } from "react";
import { push } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";
import { registerKouo, loginKouo } from "../redux/actions/kouoAuth";
import Loading from "../components/Loading";

import "../styles/App.css";
import "../styles/title.css";
import "../styles/login.css";

const Login = () => {
  // Login variables
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.kouoAuth);

  // Signup variables
  const [emailSignup, setEmailSignup] = useState("");
  const [passwordSignup, setPasswordSignup] = useState("");
  const [signupCode, setSignupCode] = useState("");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    // Check if the user/app is authenticated
    if (auth.isKouoAuthenticated) {
      dispatch(push("/link-activity"));
    }

    setLoading(false);
    // eslint-disable-next-line
  }, []);

  const handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setChecked(value);
  };

  return (
    <>
      {loading && (
        <div
          className="fullscreen"
          style={{ backgroundColor: "rgba(100, 100, 100, 0.5)" }}
        >
          <Loading title="Connecting..." loading={loading}>
            <p>
              IMPORTANT: If connection continues to load for more than 10
              seconds please refresh the page
            </p>
          </Loading>
        </div>
      )}
      <div className="container justify-center align-center">
        <div className="animated-title align-center">
          <img
            className="title-text"
            src={require("../assets/kouo.png").default}
            alt="Kouo"
          />
        </div>
        <p className="align-center" style={{ textAlign: "center" }}>
          Welcome to the Kouo Portal. If you’re new here please sign up using
          the form on the right hand side.
        </p>
        <div className="row margin-center justify-center">
          <div className="login-column">
            <input
              id="email-login"
              value={email}
              placeholder="email"
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            &nbsp;
            <input
              id="password-login"
              type="password"
              placeholder="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
            &nbsp;
            <button
              onClick={() => {
                setLoading(true);
                const signin_form = {
                  username: email,
                  password: password,
                };
                dispatch(loginKouo(signin_form));
              }}
            >
              Sign in
            </button>
            <a
              href="/forgot-password"
              style={{ fontSize: "0.7rem", textAlign: "center" }}
            >
              Forgot password?
            </a>
          </div>
          <div className="login-column">
            <input
              id="email-signup"
              value={emailSignup}
              placeholder="email"
              // TODO: Add input validation
              onChange={(e) => setEmailSignup(e.target.value)}
            ></input>
            &nbsp;
            <input
              id="password-signup"
              value={passwordSignup}
              placeholder="Password"
              type="password"
              onChange={(e) => setPasswordSignup(e.target.value)}
            ></input>
            &nbsp;
            <input
              id="signup-code"
              value={signupCode}
              placeholder="Signup code"
              onChange={(e) => setSignupCode(e.target.value)}
            ></input>
            &nbsp;
            <label>
              <input
                name="isGoing"
                type="checkbox"
                checked={checked}
                placeholder="Here"
                onChange={handleCheckboxChange}
              ></input>
              By ticking this box you confirm that you are 13 years of age or
              older and that you agree with the{" "}
              <a href="https://kouo.io/terms-conditions">Terms of Use</a> and
              the <a href="https://kouo.io/privacy">Kouo Privacy Policy</a>
            </label>
            &nbsp;
            <button
              onClick={() => {
                if (signupCode !== "Cerebrum") {
                  alert("Signup code incorrect, please check and try again");
                  return;
                }

                if (!checked) {
                  alert(
                    "Please complete the terms and services box before signin up."
                  );
                  return;
                }

                setLoading(true);
                const signup_form = {
                  email: emailSignup,
                  password: passwordSignup,
                  language: "English",
                  gender: "test",
                  age: 21,
                };
                dispatch(registerKouo(signup_form));
              }}
            >
              Sign up
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
