import React, { useState } from "react";
import { forgotPass } from "../redux/actions/kouoAuth";
import { useDispatch } from "react-redux";

import "../styles/App.css";
import "../styles/login.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  return (
    <div>
      <div className="row">
        <div style={{ flex: "1" }}></div>
        <div style={{ flex: "1" }}>
          <img
            style={{ height: "100px", margin: "auto" }}
            src={require("../assets/kouo.png").default}
            alt="Kouo"
          />
        </div>
        <div style={{ flex: "1" }}>
          {/* <button
            onClick={(e) => {
              e.preventDefault();
              window.location.assign(
                `https://form.typeform.com/to/MRuaDoGx#userid=${user.id}&username=${user.username}`
              );
            }}
            className="box align-center"
            style={{ margin: "1rem", height: "50px" }}
          >
            Wellness Survey
          </button> */}
        </div>
      </div>

      <div className="container align-center">
        <p style={{ textAlign: "center" }}>
          Check out more about Kouo and what we are doing at{" "}
          <a href="kouo.io" style={{ color: "#777" }}>
            kouo.io
          </a>
          .
        </p>
        <p style={{ fontSize: "0.8rem" }}>
          Forgot you password? Not a problem. Fill in your email below and press
          submit. If an account exists with this email, you will receive an
          email with instructions how to reset the password.
        </p>
      </div>
      <div className="row margin-center justify-center">
        <div className="login-column">
          <p style={{ fontSize: "0.7rem" }}>Fill in your email:</p>
          <input
            id="email"
            value={email}
            placeholder="email"
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          &nbsp;
          <button
            onClick={() => {
              dispatch(forgotPass(email));
              setEmail("");
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
