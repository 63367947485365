/* eslint-disable import/no-anonymous-default-export */
// file: src/redux/reducers/index.js
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import auth from "./auth";
import kouoAuth from "./kouoAuth";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    kouoAuth,
  });
