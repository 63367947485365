import { Route, Switch } from "react-router-dom";
import "./styles/App.css";
import "./styles/index.css";

import Login from "./views/Login";
import Main from "./views/Main";
import ForgotPassword from "./views/ForgotPassword";
import ResetPass from "./views/ResetPassword";
import CreativeTimer from "./views/CreativityTimer";
import PowerRituals from "./views/PowerRituals";
import SignupForm from "./views/SignupForm";
import LandingPage from "./views/LandingPage";
import Token from "./components/Token";

const App = () => {
  return (
    <div>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPass} />
        <Route path="/sign-up-fb" component={SignupForm} />
        <Route path="/wellness-intro" component={LandingPage} />

          <Route path="/link-activity" component={Main} />
          <Route path="/token" exact component={Token} />
          <Route path="/creativity-timer" exact component={CreativeTimer} />
          <Route path="/power-rituals" exact component={PowerRituals} />
      </Switch>
    </div>
  );
};

export default App;
