/* eslint-disable import/no-anonymous-default-export */
import {
  KOUO_AUTH_UPDATE_USER,
  KOUO_AUTH_LOGOUT_USER,
} from "../constants/kouoAuth";

const initialState = {
  isKouoAuthenticated: false,
};

export default function (state = initialState, action) {
  if (!action) return state;

  switch (action.type) {
    case KOUO_AUTH_UPDATE_USER:
      // save the tokens to the local storage
      localStorage.setItem("user", JSON.stringify(action.payload));
      localStorage.setItem("jwt", action.payload.jwt);

      // update the state to show the app the user is logged in
      console.log("almost done");
      return { ...state, isKouoAuthenticated: true };

    case KOUO_AUTH_LOGOUT_USER:
      localStorage.removeItem("user");
      localStorage.removeItem("jwt");

      return { ...state, isKouoAuthenticated: false };
    default:
      return state;
  }
}
