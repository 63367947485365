// file: src/redux/actions/auth.js
import {
  KOUO_AUTH_LOG_IN,
  KOUO_AUTH_SIGN_UP,
  KOUO_AUTH_UPDATE_USER,
  KOUO_AUTH_RESET_PASS,
  KOUO_AUTH_FORGOT_PASS,
  KOUO_AUTH_LOGOUT_USER,
} from "../constants/kouoAuth";

export const registerKouo = ({ email, password, language, gender, age }) => ({
  type: KOUO_AUTH_SIGN_UP,
  payload: {
    email,
    password,
    language,
    gender,
    age,
  },
});

export const loginKouo = ({ username, password }) => ({
  type: KOUO_AUTH_LOG_IN,
  payload: {
    username,
    password,
  },
});

export const updateUser = (user) => ({
  type: KOUO_AUTH_UPDATE_USER,
  payload: user,
});

export const logoutUser = () => ({
  type: KOUO_AUTH_LOGOUT_USER,
});

export const forgotPass = (email) => ({
  type: KOUO_AUTH_FORGOT_PASS,
  payload: email,
});

export const resetPass = (code, password) => ({
  type: KOUO_AUTH_RESET_PASS,
  payload: {
    code,
    password,
  },
});
