import React, { useState, useEffect } from "react";
import { push } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../components/Loading";
import { registerKouo } from "../redux/actions/kouoAuth";
import "../styles/App.css";
import "../styles/login.css";

const LandingPage = () => {
  const [loading, setLoading] = useState(false);
  // Signup variables
  const [emailSignup, setEmailSignup] = useState("");
  const [passwordSignup, setPasswordSignup] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState(18);
  const [language, setLanguage] = useState("");
  const [signupCode, setSignupCode] = useState("");
  const [checked, setChecked] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.kouoAuth);

  useEffect(() => {
    // Check if the user/app is authenticated
    if (auth.isKouoAuthenticated) {
      dispatch(push("/link-activity"));
    }

    setLoading(false);
    // eslint-disable-next-line
  }, []);

  const handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setChecked(value);
  };

  return (
    <>
      {loading && (
        <div
          className="fullscreen"
          style={{ backgroundColor: "rgba(100, 100, 100, 0.5)" }}
        >
          <Loading title="Connecting..." loading={loading}>
            <p>
              IMPORTANT: If connection continues to load for more than 10
              seconds please refresh the page
            </p>
          </Loading>
        </div>
      )}
      <div>
        <div className="row">
          <div style={{ flex: "1" }}></div>
          <div style={{ flex: "1" }}>
            <img
              style={{ height: "100px", margin: "auto" }}
              src={require("../assets/kouo.png").default}
              alt="Kouo"
            />
          </div>
          <div style={{ flex: "1" }}></div>
        </div>

        <div className="form-container align-center ">
          <p style={{ textAlign: "center" }}>
            Check out more about Kouo and what we are doing at{" "}
            <a href="www.kouo.io" style={{ color: "#777" }}>
              kouo.io
            </a>
            .
          </p>
          <h1 style={{ margin: "2rem auto" }}>Kouo Wellness Tracker </h1>
          <h2 style={{ margin: "2rem auto" }}>
            Shifting our attention to what matters
          </h2>
          <p>
            Our mission at Kouo is to redefine how we connect and interact with
            the world by addressing how we interact with our mental health.
          </p>
          <p>
            Mental health has an incredible impact on everything we do. From our
            work to our relationships everything is affected by it, however, we
            are still lacking the right tools and solutions to look after it
            effectively and in a preventive way.
          </p>
          <p>
            That's why we've started developing easy to use mental wellbeing
            solutions that adapt to our changing needs.
          </p>
          <p>
            This pilot is part of that development work to create tools that
            allow us to be more open and mindful of how we're feeling. And we
            wanted everyone to be involved in the process of shaping them so
            they can better reflect our diverse needs.
          </p>
          <h3>The structure of the pilot study</h3>
          <ul>
            <li>
              <p>
                For optimum results we recommend filling out the Wellness
                Tracker at least <u>x2 a day</u>. You can opt for reminder texts
                or emails twice a day (at 12pm and 5pm GMT) by filling in your
                number and email address in the personal preferences form on the
                portal.
              </p>
            </li>
            <li>
              <p>
                At the end of each week, you’ll receive a personalised report
                designed to help you recognise your emotional patterns in
                relation to your habits and activities.
              </p>
            </li>
            <li>
              <p>
                The type of insights you receive might change during the pilot.
                Those changes will be driven by the feedback we receive from
                you.
              </p>
            </li>
            <li>
              <p>
                Each day you'll be able to provide us with feedback and
                thoughts. At the end of each week, you can tell us how you found
                the insights you’ve received.
              </p>
            </li>
          </ul>
          <p>
            If this sounds interesting, you can fill out your details below to
            receive an invitation to the study: (Psst, the sign up code is{" "}
            <b>Wellness2022</b>). Once you've created your account, don't forget
            to set your personal preferences on the portal. It will help us
            tailor the experience to your needs.
          </p>
          <div className="login-column-middle">
            <label htmlFor="gender">Gender</label>
            <select
              id="gender"
              value={gender}
              placeholder="Select your gender"
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="cis female"> Cis Female </option>
              <option value="cis male"> Cis Male </option>
              <option value="cis man"> Cis Man </option>
              <option value="cis woman"> Cis Woman </option>
              <option value="cisgender female"> Cisgender Female </option>
              <option value="cisgender male"> Cisgender Male </option>
              <option value="cisgender man"> Cisgender Man </option>
              <option value="cisgender woman"> Cisgender Woman </option>
              <option value="gender fluid"> Gender Fluid </option>
              <option value="gender nonconforming">
                {" "}
                Gender Nonconforming{" "}
              </option>
              <option value="gender questioning"> Gender Questioning </option>
              <option value="gender variant"> Gender Variant </option>
              <option value="trans female"> Trans Female </option>
              <option value="trans male"> Trans Male </option>
              <option value="trans man"> Trans Man </option>
              <option value="trans person"> Trans Person </option>
              <option value="trans woman"> Trans Woman </option>
              <option value="transgender female"> Transgender Female </option>
              <option value="transgender male"> Transgender Male </option>
              <option value="transgender man"> Transgender Man </option>
              <option value="transgender person"> Transgender Person </option>
              <option value="transgender woman"> Transgender Woman </option>
              <option value="transsexual female"> Transsexual Female </option>
              <option value="transsexual male"> Transsexual Male </option>
              <option value="transsexual man"> Transsexual Man </option>
              <option value="transsexual person"> Transsexual Person </option>
              <option value="transsexual woman"> Transsexual Woman </option>
              <option value="female to male"> Female to Male</option>
              <option value="male to female"> Male to Female</option>
              <option value="non-binary"> Non-binary</option>
              <option value="two-spirit"> Two-Spirit</option>
              <option value="agender"> Agender</option>
              <option value="androgyne"> Androgyne</option>
              <option value="androgynous"> Androgynous</option>
              <option value="bigender"> Bigender</option>
              <option value="cis"> Cis</option>
              <option value="cisgender"> Cisgender</option>
              <option value="ftm"> FTM</option>
              <option value="genderqueer"> Genderqueer</option>
              <option value="intersex"> Intersex</option>
              <option value="mtf"> MTF</option>
              <option value="neither"> Neither</option>
              <option value="neutrois"> Neutrois</option>
              <option value="pangender"> Pangender</option>
              <option value="trans"> Trans</option>
              <option value="transfeminine"> Transfeminine</option>
              <option value="transgender"> Transgender</option>
              <option value="transmasculine"> Transmasculine</option>
              <option value="transsexual"> Transsexual</option>
              <option value="other"> Other/Prefer not to say</option>
            </select>
            &nbsp;
            <label htmlFor="age">Age</label>
            <input
              id="age"
              value={age}
              type="number"
              onChange={(e) => setAge(e.target.value)}
            ></input>
            &nbsp;
            <input
              id="language"
              value={language}
              placeholder="Native Language"
              onChange={(e) => setLanguage(e.target.value)}
            ></input>
            &nbsp;
            <input
              id="email-signup"
              value={emailSignup}
              placeholder="email"
              // TODO: Add input validation
              onChange={(e) => setEmailSignup(e.target.value)}
            ></input>
            &nbsp;
            <input
              id="password-signup"
              value={passwordSignup}
              placeholder="Password"
              type="password"
              onChange={(e) => setPasswordSignup(e.target.value)}
            ></input>
            &nbsp;
            <input
              id="signup-code"
              value={signupCode}
              placeholder="Signup code"
              onChange={(e) => setSignupCode(e.target.value)}
            ></input>
            &nbsp;
            <label>
              <input
                name="isGoing"
                type="checkbox"
                checked={checked}
                placeholder="Here"
                onChange={handleCheckboxChange}
              ></input>
              By ticking this box you confirm that you are 13 years of age or
              older and that you agree with the{" "}
              <a href="https://kouo.io/terms-conditions">Terms of Use</a> and
              the <a href="https://kouo.io/privacy">Kouo Privacy Policy</a>
            </label>
            &nbsp;
            <button
              onClick={() => {
                if (signupCode !== process.env.REACT_APP_SIGNUP_CODE) {
                  alert("Signup code incorrect, please check and try again");
                  return;
                }

                if (!checked) {
                  alert(
                    "Please complete the terms and services box before signin up."
                  );
                  return;
                }

                setLoading(true);
                const signup_form = {
                  email: emailSignup,
                  password: passwordSignup,
                  language: language,
                  gender: gender,
                  age: age,
                };
                dispatch(registerKouo(signup_form));
              }}
            >
              Sign up
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
